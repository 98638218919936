<template>
	<!-- eslint-disable -->
	<v-container
		
		class="pa-10 pa-sm-16"
	>
		<v-row class="ma-0">
			<v-col cols="2" class="pa-0">
				<z-navigation-drawer />
			</v-col>
			<v-col v-if="hasPermission($route.meta.permission)" class="pa-0">
				<v-card flat class="ma-0">
					<v-container>
						<v-row class="ma-0 munjz-bg-grey">
							<v-col cols="10" sm="8" class="d-flex align-center">
								<span>Settings <v-icon>mdi-chevron-right</v-icon></span>
								<span class="text-sm-h2 text-h3">Company Profile</span>
							</v-col>
							<v-col v-if="hasPermission('Edit Company Profile')" class="d-flex justify-center align-center justify-sm-end">
								<v-btn
									small
									class="blue-grey lighten-4"
									fab
									text
									@click="editProfile"
									icon
								>
									<v-icon>mdi-{{ editMode ? "close" : "pencil" }}</v-icon>
								</v-btn>
							</v-col>
						</v-row>
						<v-row class="ma-0">
							<v-col cols="12" sm="3" class="me-auto d-flex-col justify-center">
								<input
									v-if="editMode"
									type="file"
									id="mainImagePicker"
									hidden
									v-on:change="getImage($event, 'vm.profileImageB64')"
								/>
								<img
									class="img-fluid"
									height="100"
									:src="
										profileImageB64
											? profileImageB64
											: 'https://via.placeholder.com/150'
									"
									@click="showImagePicker('mainImagePicker')"
									style="border: 1px solid #bdbdbd; height: 100px; position: relative"
									width="100"
								/>
								<v-col cols="2" class="pa-0">
									<v-btn
										v-if="editMode"
										small
										text
										class="mx-0 pa-0"
										color="primary"
										@click="saveProfileImage"
									>
										<v-icon>mdi-upload</v-icon>Upload logo
									</v-btn>
								</v-col>
							</v-col>
							<v-col cols="12">
								<v-text-field
									v-model="companyProfile.partner_detail.company_name"
									label="Comapny name"
									required
									readonly
									outlined
								></v-text-field>
							</v-col>
							<v-col>
								<h3>Legal Information</h3>
							</v-col>
							<v-col cols="12">
								<v-text-field
									v-model="companyProfile.partner_detail.company_name"
									label="Legal Company name"
									hint="The same name in the commercial record"
									required
									readonly
									outlined
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									v-model="companyProfile.partner_detail.vat_number"
									label="VAT Number"
									type="number"
									required
									:rules="[val => val !== null || val != ``]"
									readonly
									outlined
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									v-model="companyProfile.partner_detail.cr_number"
									label="CR Number"
									type="number"
									required
									readonly
									outlined
								></v-text-field>
							</v-col>
							<v-col cols="12" class="pa-0 mb-10">
								<h3>Comapny Paper</h3>
							</v-col>
							<v-col cols="4" sm="2" class="pa-0">
								<v-img
									max-width="100"
									src="../../../assets/pdfLogo.png"
								></v-img>
								<a class="mt-3" :href="companyProfile.cr_file" target="_blank"
									>CR: {{ companyProfile.partner_detail.cr_number }}</a
								>
							</v-col>
							<v-col cols="4" sm="2" class="pa-0">
								<v-img
									max-width="100"
									src="../../../assets/pdfLogo.png"
								></v-img>
								<a
									class="mt-3"
									:href="companyProfile.vat_certificate"
									target="_blank"
									>VAT Certificate:
									{{ companyProfile.partner_detail.vat_number }}</a
								>
							</v-col>
							<v-col class="mt-5" cols="12" v-if="editMode">
								<v-btn color="primary">
									<v-icon color="white">mdi-plus</v-icon>Save
								</v-btn>
							</v-col>
						</v-row>
					</v-container>
				</v-card>
			</v-col>
	
			<v-col v-else cols="12" class="d-flex justify-center">
				<v-card flat>
					<v-card-title
						><v-card class="red">
							<h1 class="red pa-6">
								You Don't have Permission to view this page !
							</h1></v-card
						></v-card-title
					>
				</v-card>
			</v-col>
		</v-row>
		<v-snackbar
			top
			class="rounded-pill"
			v-model="paymentSnackbar"
			color="green"
		>
			<v-icon left color="white">mdi-check</v-icon>
			Logo Updated Successfully !
		</v-snackbar>

			</v-col>
		</v-row>
	</v-container>

</template>
<script>
import ZNavigationDrawer from "../components/core/ZNavigationDrawer.vue";
export default {
	components: { "z-navigation-drawer": ZNavigationDrawer },
	data() {
		return {
			paymentSnackbar: false,
			profileImageB64: null,
			editMode: false,
			user_id: localStorage.getItem("userid"),
			token: localStorage.getItem("token"),
			// settingsList: [
			//   { title: "Company Profile", path: "/company-profile" },
			//   { title: "Roles and Permissions", path: "/roles-list" },
			//   { title: "Help Desk", path: "/helpdesk" },
			// ],

			companyProfile: {
				partner_detail: {
					company_name: String,
					cr_number: String,
					vat_number: String,
				},
			},
			companyLogo: "",

			imageNotFound: true,
		};
	},
	computed: {},
	watch: {},
	mounted() {
		this.getcompanyProfile();
	},
	created() {},
	methods: {
		saveProfileImage() {
			axios({
				method: "post",
				url: "/api/v3/b2b/uploadProfileImage",
				data: {
					profile_image: this.profileImageB64,
				},
				headers: {
					"X-Authorization": this.token,
				},
			})
				.then((res) => {
					this.paymentSnackbar = true;
				})
				.catch((err) => console.log(err));
		},
		showImagePicker(inputId, index) {
			if (this.editMode) {
				document.getElementById(inputId).click();
				this.currentValueImage = index;
			}
		},
		getImage(e, imgVariable) {
			let vm = this;
			let files = e.target.files || e.dataTransfer.files;
			if (!files.length) return;
			let reader = new FileReader();
			reader.onload = (e) => {
				eval(imgVariable + " = " + "'" + e.target.result + "'");
			};
			reader.readAsDataURL(files[0]);
		},

		editProfile() {
			this.editMode = !this.editMode;
		},
		getcompanyProfile() {
			axios
				.get("api/v3/b2b/partner-details", {
					headers: { "X-Authorization": this.token },
					params: {
						user_id: this.user_id,
					},
				})
				.then((res) => {
					this.companyProfile = res.data;
					this.profileImageB64 = this.companyProfile.company_logo;
				});
		},
	},
};
</script>
